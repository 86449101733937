/* Cards */
.card {
  border: 0;
  .card-header { border-bottom: 0px; }

  .card-body {
    padding: $card-padding-y $card-padding-x;
    + .card-body {
      padding-top: 0;
    }
  }

  &.card-outline-success { border: 1px solid theme-color("success"); }
	&.card-outline-primary { border: 1px solid theme-color("primary"); }
  &.card-outline-warning { border: 1px solid theme-color("warning"); }
  &.card-outline-danger { border: 1px solid theme-color("danger"); }
	&.card-rounded { @include border-radius(5px); }

  &.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
  }

  &.card-circle-progress {
    color: $white;
    text-align: center;
  }
}