.ql-editor {
  strong {
    font-weight: $font-weight-bold;
  }

  ol li,
  ul li {
    font-weight: $font-weight-normal;
  }

  p {
    font-size: 1rem;
    font-weight: $font-weight-normal;
  }
}
