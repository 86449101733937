.app-wrapper {
  padding-top: $navbar-height;
  min-height: calc(100vh - #{$footer-height});

  .landing-page {
    position: relative;
    .hero-img {
      width: 100%;
      height: 50%;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      color: $white;
      .hero-content {
        background: rgba(56, 49, 46, 0.8);
        padding: 30px;
      }
    }
    .bg-img {
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
    }
    .hero-description {
      font-size: 20px;
    }
    .hero-carousel {
      width: 100%;
      height: 550px;
      top: 0;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      color: $white;
      .hero-content {
        background: rgba(56, 49, 46, 0.8);
        padding: 30px;
      }
      @media (max-width: 992px) {
        left: 0px;
        margin: 0;
      }
      // @media (max-width: 767px) {
      //   margin: 0;
      // }
      @media (max-width: 576px) {
        height: 700px;
        .hero-content {
          min-height: 700px;
        }
        .hero-title {
          padding-top: 30px;
        }
      }
      @media (max-width: 380px) {
        height: 800px;
        .hero-content {
          min-height: 800px;
        }
        .hero-title {
          padding-top: 10px;
        }
      }
    }
  }

  .carousel.slide {
    min-height: 550px;
    .carousel-img {
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 550px;
    }
    .carousel-control-prev, .carousel-control-next {
      width: 8%;
      z-index: 100;
      @media (max-width: 992px) {
        display: none;
      }
    }
    @media (max-width: 576px) {
      min-height: 700px;
      .carousel-img {
        min-height: 700px;
      }
    }
    @media (max-width: 380px) {
      min-height: 800px;
      .carousel-img {
        min-height: 800px;
      }
    }
  }

  .static-documents {
    li {
      font-size: 1rem;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 0px;
  }
}

.skip-link {
  position: absolute;
  width: 150px;
  height: 30px;

  &:focus {
    position: static;
  }
}
