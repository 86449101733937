/* Typography */
address {
  p {
    margin-bottom: 0;
  }
}

.page-title {
  color: $black;
  margin: 0.38rem 0 0.75rem 0;
}

.card-title {
  color: black;
  margin-bottom: 1.125rem;
  text-transform: capitalize;
}

.card-subtitle {
  @extend .text-gray;
  font-weight: $font-weight-normal;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.card-description {
  margin-bottom: .9375rem;
  font-weight: $font-weight-normal;
}

a { color: $primary }

a {
  &.text-primary {
    &:hover {
      color: theme-color(primary) !important;
    }
  }
}

// FONT
.caption-14 {
  font-size: 14px !important;
}

.caption-12 {
  font-size: 12px !important;
}

small,
input,
textarea,
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select {
  font-weight: $font-weight-normal !important;
}

.text-hover-none {
  &:hover {
    color: $black;
  }
}

.text-hover {
  &:hover {
    color: $primary;
  }
}

.text-link {
  color: $primary;
  &:hover {
    cursor: pointer;
    color: $primary;
    text-decoration: underline;
  }
}

.text-muted-light {
  color: $gray-400;
}
