.quill {
  overflow-y: auto;
  resize: vertical;
  height: 140px;
  margin-bottom: 0px;
}

.ql-container {
  border: 0px solid $gray-400 !important;
}

.ql-toolbar {
  border: 0px solid $gray-400 !important;
  border-bottom: 1px solid $gray-400 !important;
}

.ql-editor {
  min-height: 140px;
  border: 0px solid $gray-400;
}

.text-editor {
  border: 1px solid $gray-400;
  border-radius: 4px;
  background-color: $white;
}
