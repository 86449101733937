.dropdown-menu {

  .dropdown-item {
    &:active { background: initial; }

    .nav-mobile-title {
      font-size: 1.2rem;
    }
  }
}
