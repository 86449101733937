// Custom Navbar

.navbar {
  position: fixed;
  height: $navbar-height;
  width: 100%;
  transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;

  &.sidenav-active {
    width: calc(100% - $sidenav-width);
  }
}



// Custom Sidenav

.sidenav {
  position: fixed;
  width: $sidenav-width;
  left: -$sidenav-width;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  transition: left 0.25s ease-out;
  -webkit-transition: left 0.25s ease-out;
  -moz-transition: left 0.25s ease-out;
  -ms-transition: left 0.25s ease-out;

  &.active {
    left: 0;
  }

  .sidenav-logo {
    width: 100%;
    max-height: 125px;
  }
}



// App Wrapper

.app-wrapper {
  position: relative;
  width: 100%;
  left: 0;
  transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;

  &.sidenav-active {
    left: $sidenav-width;
    width: calc(100% - $sidenav-width);
  }

  /* Check if there's a .content-wrapper directly after .app-wrapper */
// .app-wrapper:has(.content-wrapper) {
//   background-color: red; /* Apply red color if .content-wrapper is present */
// }

  .content-wrapper {
    position: relative;
    // margin-top: $navbar-height;
    // min-height: calc(100vh - $navbar-height); // ! Remove?
    padding: 1.5rem;

    .content-wrapper-inner {
      min-height: 75vh; // ! Remove?
    }
  }
}



@media screen and (max-width: 576px) {
  .sidenav {
    width: 100%;
    left: -100%;
  }

  .app-wrapper {

    &.sidenav-active {
      display: none;
    }

    .content-wrapper {
      padding: 0;

      .content-wrapper-inner {
        min-height: calc(100vh - $navbar-height);
      }
    }
  }
}


.meter-nav {
  .nav-item{
    box-sizing: border-box;
    .nav-link {
      padding: 10px 0px 10px 8px;
      box-sizing: border-box;
      &.active {
        border-bottom: 2px solid $primary;
        color: $primary
      }
    }
    &:hover,
    &:focus {
      border-bottom: 2px solid $gray-200;
    }
  }
}

/* Navbar Legacy */
.navbar {
  background-color: $white;
  font-weight: $font-weight-normal;
  transition:background $action-transition-duration $action-transition-timing-function;
  -webkit-transition:background $action-transition-duration $action-transition-timing-function;
  -moz-transition:background $action-transition-duration $action-transition-timing-function;
  -ms-transition:background $action-transition-duration $action-transition-timing-function;

  .navbar-menu-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    box-shadow: $box-shadow-nav;
    color: $gray-800;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    height: $navbar-height;

    .navbar-item {
      padding: 0;
      p {
        padding: 0.25rem 1.5rem;
      }
    }

    .navbar-nav { // top nav dropdowns
      flex-direction: row;
      align-items: center;
      height: 100%;

      .nav-item { // top nav dropdowns - NavItem icons container
        height: 100%;
        text-align: center;

        &.active {
          color: $primary;
        }
        &:hover, &.selected { // top nave dropdowns
          color: $primary;
          background-color: $gray-100;
        }

        .nav-link { // top nav dropdowns - NavItem icons
          color: inherit;
          font-size: $navbar-font-size;
          height: 100%;
          padding: 8px;
          line-height: 1;

          i {
            font-size: $navbar-icon-font-size;
            vertical-align: middle;
          }

          img {
            height: 24px;
            width: 24px;
            border-radius: 100%;
            background: $white;
            margin-top: 2px;
          }
        }

        &.dropdown { // top nav dropdowns - <li>, NavItem icon plus content
          .dropdown-toggle {
            padding: 0;
            &:after {
              content: "\e604";
              font-family: 'simple-line-icons';
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              font-size: .625rem;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              border: 0;
              vertical-align: .1em;
            }
          }

          i { vertical-align: middle; }

          .nav-icon {
            font-size: 1.4rem;
          }

          .navbar-dropdown { // top nav dropdowns - the content under each <li>
            // background-color: $primary;
            font-size: 1rem;
            margin-top: 0;
            position: absolute;
            top: calc(#{$navbar-height} + 1px);
            right: 0px;
            left: auto;
            min-width: 0;
            transition: height .5s ease;

            &.dropdown-left {
              left: 0px;
              right: auto;
            }

            -webkit-box-shadow: 0px 0px 13px -3px rgba(0,0,0,0.10);
            -moz-box-shadow: 0px 0px 13px -3px rgba(0,0,0,0.10);
            box-shadow: 0px 0px 13px -3px rgba(0,0,0,0.10);
            @extend .dropdownAnimation;
            border: 0px solid rgba(182, 182, 182, 0.1);
            border-radius: 0px;
            padding: 0;
            overflow: hidden;

            .dropdown-item { // top nav dropdowns - rows inside content container
              @extend .d-flex;
              @extend .align-items-center;
              margin-bottom: 0;
              min-width: 230px;
              padding: 0px;
              color: $gray-800;

              .icon {
                height: 40px;
                width: 54px;
                display: flex;
                justify-content: center;
                padding: 12px 0;
                line-height: 1;
              }

              &:hover {
                .icon,
                .body {
                  background-color: $gray-200;
                }
              }

              .body {
                height: 50px;
                padding: 12px 2px;
                flex-grow: 1;
                line-height: 1;
                &.no-hover {
                  background-color: $white;
                }
              }
            }

            .dropdown-divider { // top nav dropdowns - divides rows
              margin: 0;
            }

            .navbar-dropdown-subdropdown { // top nav dropdowns - subdropdowns content containers (primary, secondary) - (ADDED BY JY)
              min-width: 480px;
              max-width: 100vw;

              @media (max-width:576px) {
                min-width: 0px;
              }
            }

            .navbar-dropdown-primary-enter { // top nav dropdowns - below are CSSTransition animations - (ADDED BY JY)
              position: absolute;
              transform: translateX(-100%);
            }

            .navbar-dropdown-primary-enter-active {
              transform: translateX(0%);
              transition: all .4s ease;
            }
            .navbar-dropdown-primary-exit {
              position: absolute;
            }
            .navbar-dropdown-primary-exit-active {
              transform: translateX(-100%);
              transition: all .4s ease;
            }

            .navbar-dropdown-secondary-enter {
              transform: translateX(100%);
            }
            .navbar-dropdown-secondary-enter-active {
              transform: translateX(0%);
              transition: all .4s ease;
            }
            .navbar-dropdown-secondary-exit-active {
              transform: translateX(100%);
              transition: all .4s ease;
            }
          }
          @media (max-width:576px) {
            .navbar-dropdown { // top nav dropdowns - css for mobile full width content
              right: 0;
              // top: $navbar-height;
              border-radius: 0px;
              &.dropdown-left {
                left: 0;
                right: 0;
              }
            }
          }
        }
      }
      &.navbar-nav-right {
        @media (min-width: 992px) {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
    }
  }
}

.nav-tabs {
  border-bottom: 1px solid transparent;

  .nav-item {
    margin-bottom: 0px;
    text-align: center;
    .nav-link {
      line-height: 1;
      padding: 20px 8px 12px 8px;
      font-size: $default-font-size;
      color: $gray-600;
      border-radius: 0;
      border: 0px;
      border-bottom: 2px solid transparent;

      span {
        width: 20px;
        margin-right: 4px;
      }

      @media (max-width: 767px) {
        padding: 13px 10px;
      }

      &.active {
        border-bottom: 2px solid $primary;
        color: $primary;
      }

      &:hover,
      &:focus {
        border-bottom: 2px solid $gray-600;
        &.active {
          border-bottom: 2px solid $primary;
          color: $primary;
        }
      }
    }
  }
}

@media (max-width:991px) {
  .navbar {
    flex-direction: row;
    .navbar-menu-wrapper .navbar-nav .nav-item {
      &.dropdown .navbar-dropdown {
        width: 100vw;
      }
      .nav-link {
        padding: 8px;
        height: auto;
        &.mobile-menu {
          width: 50px;
          padding: 8px 15px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .navbar {
    .navbar-menu-wrapper .navbar-nav .nav-item {
      .nav-link {
        padding: 8px;
        height: auto;
        &.mobile-menu {
          width: 50px;
          padding: 8px 15px;
        }
      }
    }
  }
}
