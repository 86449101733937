// Custom Button

.btn {
  // scss-docs-start btn-css-vars
  // --#{$prefix}btn-padding-x: #{$btn-padding-x};
  // --#{$prefix}btn-padding-y: #{$btn-padding-y};
  // --#{$prefix}btn-font-family: #{$btn-font-family};
  // @include rfs($btn-font-size, --#{$prefix}btn-font-size);
  // --#{$prefix}btn-font-weight: #{$btn-font-weight};
  // --#{$prefix}btn-line-height: #{$btn-line-height};
  // --#{$prefix}btn-color: #{$body-color};
  // --#{$prefix}btn-bg: transparent;
  // --#{$prefix}btn-border-width: #{$btn-border-width};
  // --#{$prefix}btn-border-color: transparent;
  // --#{$prefix}btn-border-radius: #{$btn-border-radius};
  // --#{$prefix}btn-box-shadow: #{$btn-box-shadow};
  // --#{$prefix}btn-disabled-opacity: #{$btn-disabled-opacity};
  --#{$prefix}btn-focus-box-shadow: 0 0 .25rem #{$btn-focus-width} rgba(var(--#{$prefix}btn-focus-shadow-rgb), .5);
  // scss-docs-end btn-css-vars

  .btn-check:focus + &,
  &:focus {

    @if $enable-shadows {
      box-shadow: var(--#{$prefix}btn-box-shadow), var(--#{$prefix}btn-focus-box-shadow) !important;
    } @else {
      box-shadow: var(--#{$prefix}btn-focus-box-shadow) !important;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant-custom(
      $value,
      $value
    );
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @if $color == "light" {
      @include button-outline-variant-custom(
        $value,
        $border: rgba(255, 255, 255, 0.1),
        $active-background: rgba(255, 255, 255, 0.05)
      );
    } @else {
      @include button-outline-variant-custom(
        $value,
        $border: rgba(0, 0, 0, 0.1),
        $active-background: rgba(0, 0, 0, 0.05)
      );
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-ghost-#{$color} {
    @if $color == "light" {
      @include button-ghost-variant(
        $value,
        $active-background: rgba(255, 255, 255, 0.05)
      );
    } @else {
      @include button-ghost-variant(
        $value,
        $active-background: rgba(0, 0, 0, 0.05)
      );
    }
  }
}