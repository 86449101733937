// Custom Tables

.search-icon {
  top: calc(50% - 0.5rem);
  left: 0.625rem;
}

.search-x-icon {
  top: calc(50% - 0.5rem);
  right: 0.625rem;
}

.table-button-muted {
  &:not(:hover, :active, :focus) {
    color: $text-muted;
  }
}

.drag-drop-icon {
  &:not(:hover, :active, :focus) {
    color: $text-muted;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: move;
  }
}

.table-wrapper {
  background: $white;
  position: relative;
}

.table {
  width: 100%;
  margin: 0;
  display: grid;
  overflow: auto;
  border-bottom: 1px solid $gray-400;

  .thead,
  .tbody,
  .tr {
    display: contents;

    .th:not(:last-child) .th-divider {
      position: absolute;
      width: 2px;
      right: 0;
      z-index: 0;
      background-color: $gray-400;
    }

    .edit-background-color {
      background-color: #D3DDFC;
    }

    &>.sticky-col {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      z-index: 2;
    }

    .resize-handle {
      position: absolute;
      cursor: col-resize;
      width: 8px;
      right: 0;
      top: 0;
      z-index: 1;
      border-right: 4px solid transparent;

      &:active {
        border-color: $primary;
      }
    }

    .sticky-handle {
      position: absolute;
      width: 7px;
      right: 0;
      top: 0;
      z-index: 1;
    }


    .edit {
      visibility: hidden;
      transition: visibility .06s ease-in;
      padding: 10px;
      max-height: 50px;
      align-self: center;
    }

    .save-cancel {
      padding: 10px;
      max-height: 50px;
      align-self: center;
    }

    .card-hover {
      max-width: 650px;
      color: $gray-700;
    }

    .card-hover:hover>*:not(.card-header) {
      background-color: $gray-100;
      border-radius: 5px;
    }

    .card-hover > .card-header {
      background-color: tint-color($primary, 80%) !important;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .card-hover:hover> .card-header {
      background-color: tint-color($primary, 70%) !important;
    }
  }

  .tr>.td {
    background-color: $white;

    .skeleton {
      background-color: $gray-200;
    }
  }

  .tbody .tr:hover>*:not(.edit-background-color):not(.active-bg) {
    background-color: $gray-100;
  }

  .tbody .tr .active-bg:not(.edit-background-color) {
    background-color: RGB(229, 243, 248);
  }

  .th {
    position: relative;
    font-size: 18px;
    background-color: $white;
    text-align: left;

    h6 {
      color: $gray-700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .th-buttons {
      position: absolute;
      right: 0;
      margin-right: 10px;
      visibility: hidden;
    }
    .th-buttons:has(.show) {
      .show {
        visibility: visible;
      }
    }
    .th-buttons:has(.active-sort) {
      .active-sort {
        visibility: visible;
      }
    }

    &:hover {
      .th-buttons {
        visibility: visible;
      }
    }
  }

  .td {
    border-top: 1px solid $gray-400;
    text-align: left;

    input,
    a,
    h6 {
      display: block;
    }
  }

  .td:hover .edit {
    visibility: visible
  }
}

.star-checkbox input[type='checkbox'] {
  display: none;
  cursor: pointer;
}

.star-checkbox input[type='checkbox']+label:before {
  font-family: 'Font Awesome 6 Pro';
  content: "\f005";
  cursor: pointer;
  font-weight: 100;
}

.star-checkbox input[type='checkbox']:checked+label:before {
  font-weight: 600;
  color: $warning;
  animation-name: checkbox;
  animation-duration: 100ms;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

/* SIZE SM & BIGGER */
@media screen and (min-width: 576px) {
  #filterKey, #operatorValue, #filterValue {
    width: 160px;
  }
}

/* SIZE MD & BIGGER */
@media screen and (min-width: 768px) {
  #filterKey, #operatorValue, #filterValue {
    width: 175px;
  }
}

@keyframes checkbox {
  from{ font-weight: 100 }
  to{ font-weight: 600; color: $warning}
}

.list-item-box {
  border: 1px solid $gray-200;
  box-shadow: 0px 1px 2px 0px #D4D4D4;
  border-radius: 4px;
}
