// Custom Toasts

.Toastify {
  &__close-button {
    color: RGB(0, 0, 0);
  }
  &__toast-container {
    width: 400px !important;
  }
  &__toast {
    background: var(--toastify-color-light);
  }
}